<template>
  <div class="product-grid" @click="onViewProduct(data.ProductKey)">
    <div v-if="data?.PercentageDiscount > 0 && mode === 'SPECIAL_PRICE'" class="discount">
      <span v-if="languageCode == 'EN'"> {{ $h.formatInteger(data.PercentageDiscount) }}% {{ $t.getTranslation('LBL_OFF') }} </span>
      <span v-else> {{ $t.getTranslation('LBL_OFF') }} {{ $h.formatInteger(data.PercentageDiscount) }}% </span>
    </div>

    <div class="sale-container" v-if="data?.ProductSaleStatus == 'ACTIVE' || data.GroupBuyId > 0">
      <div class="image-container sale-image">
        <img loading="lazy" :src="helpers.getImage(data.PrimaryImage, 'PRODUCT', null, true, data)" :alt="$t.getTranslation('LBL_PRODUCT_IMAGE')" class="product-image" />
        <div v-if="data?.PackingQty && data?.PackingQty > 1" class="packing-qty-grid"><font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x {{ data.PackingQty }}</div>
      </div>

      <FlashSaleGroupComponent :data="data" />
    </div>

    <div class="image-container" v-else>
      <div v-if="!userToken" class="ribbon-container-grid">
        <div class="ribbon-new-grid ribbon-top-left-new-grid" v-if="data?.IsNew && data?.IsNew > 0">
          <span>{{ $t.getTranslation('LBL_ISNEW') }}</span>
        </div>
        <div class="ribbon-hot-grid ribbon-top-left-hot-grid" v-if="data?.IsHot && data?.IsHot > 0">
          <span>{{ $t.getTranslation('LBL_ISHOT') }}</span>
          <div class="bg-sticker-grid"><img :src="require('@/assets/images/sticker-flower2.png')" loading="lazy" /></div>
        </div>
        <div class="ribbon-sal-grid ribbon-top-left-sal-grid" v-if="data?.IsSale && data?.IsSale > 0">
          <span>{{ $t.getTranslation('LBL_ISSALE') }}</span>
          <div class="bg-sticker-grid"><img :src="require('@/assets/images/sticker-flower2.png')" loading="lazy" /></div>
        </div>
        <div class="ribbon-best-grid ribbon-top-left-best-grid" v-if="data?.IsBest && data?.IsBest > 0">
          <span>{{ $t.getTranslation('LBL_ISBEST') }}</span>
          <div class="bg-sticker-grid"><img :src="require('@/assets/images/sticker-best.png')" loading="lazy" /></div>
        </div>
      </div>

      <img :src="helpers.getImage(data.PrimaryImage, 'PRODUCT', null, true, data)" :alt="$t.getTranslation('LBL_PRODUCT_IMAGE')" class="product-image" loading="lazy" />

      <span class="year">{{ $h.formatTrimString(data.Year, 4) }}</span>

      <div v-if="data?.TotalInventory > 0 && mode === 'SPECIAL_PRICE'" class="quantity">
        {{ data.TotalInventory }}
        <small>{{ $t.getTranslation('LBL_IN_STOCK') }}</small>
      </div>

      <div v-if="mode === 'SPECIAL_PRICE'" class="special-price">{{ $t.getTranslation('LBL_618_BUY') }}</div>

      <div v-if="data?.PackingQty && data?.PackingQty > 1" class="packing-qty-grid"><font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x {{ data.PackingQty }}</div>
    </div>

    <div class="product-info">
      <h4>{{ $h.formatTrimString(data.Name, 200) }}</h4>
      <div>
        <div v-if="data?.ProductSaleAmount && data?.ProductSaleStatus == 'ACTIVE'">
          <span>
            <h5 class="price sale-price">{{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}</h5>
            <h5 class="price">{{ $h.formatPrice(data?.ProductSaleAmount, data?.CurrencyCode) }}</h5>
          </span>
        </div>
        <div v-else-if="data?.DiscountPrice && data?.DiscountPrice < data?.RegularSellingPrice && showPrice">
          <span v-if="data?.IsFuture == 0">
            <h5 class="sale-price" style="margin-right: 0px !important">{{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}</h5>
            <h5 class="prod-price-b">{{ $h.formatPrice(data?.DiscountPrice, data?.CurrencyCode) }}</h5>
          </span>
        </div>

        <h5 v-else class="prod-price-b">
          <span v-if="data?.IsFuture == 0 && showPrice">
            {{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}
          </span>
        </h5>
      </div>

      <div class="product-star-rating">
        <star-rating :increment="0.1" :star-size="13" :show-rating="false" :rating="rating" :read-only="true"></star-rating>
      </div>
    </div>

    <div v-if="data?.ProductSaleStatus == 'ACTIVE' || data?.GroupBuyId > 0" class="sale-text">
      <div v-if="data?.ProductSaleStatus == 'ACTIVE'">
        <p>
          {{ $t.getTranslation('LBL_STARTS_IN') }}
          <strong> {{ $h.formatMonthDay(data.SaleFrom) }} {{ $h.formatSaleTime(data.SaleFrom) }} </strong>
        </p>
        <p>
          {{ $t.getTranslation('LBL_ENDS_IN') }}
          <strong> {{ $h.formatMonthDay(data.SaleTo) }} {{ $h.formatSaleTime(data.SaleTo) }} </strong>
        </p>
      </div>
      <div v-if="data?.GroupBuyId > 0">
        <div v-if="data?.IsGroupBuyComingSoon == 1" class="ribbon-new-grid ribbon-top-left-new-grid ribbon-soon">
          <span>{{ $t.getTranslation('LBL_SOON') }}</span>
        </div>
        <p>
          {{ $t.getTranslation('LBL_START_DATE') }} <strong> {{ $h.formatDate(data.StartDate) }} </strong>
        </p>
        <p>
          {{ $t.getTranslation('LBL_END_DATE') }} <strong>{{ $h.formatDate(data.EndDate) }} </strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, ref, computed, defineAsyncComponent } from 'vue'
import { helpers } from '@/utils/helpers.js'
import { useStore } from '@/store'

import { f7 } from 'framework7-vue'

//import StarRating from '@/components/rating/star-rating.vue'
//import FlashSaleGroupComponent from '@/components/FlashSaleGroupComponent.vue'

export default defineComponent({
  name: 'ProductGridCardComponent',
  components: {
    StarRating: defineAsyncComponent(() => import(/* webpackChunkName: "star rating" */ /* webpackMode: "lazy" */ '@/components/rating/star-rating.vue')),
    FlashSaleGroupComponent: defineAsyncComponent(() => import(/* webpackChunkName: "flash-sale" */ /* webpackMode: "lazy" */ '@/components/FlashSaleGroupComponent.vue'))
  },
  props: {
    data: Object,
    bundleId: Number,
    showPrice: {
      default: true,
      type: Boolean
    },
    mode: String
  },
  setup(props) {
    const store = useStore()

    const $f7router = f7.views.get('#main-view').router
    const rating = ref(0)
    const userToken = computed(() => store.getters['user/getData']?.Token)
    const languageCode = computed(() => store.getters['translation/getLanguage'])

    if (props?.data?.TotalRating <= 0) {
      rating.value = 4.5
    } else {
      rating.value = props.data.TotalRating
    }

    const onViewProduct = (ProductKey) => {
      // if (!userToken.value) {
      //   $f7router.navigate({ name: "loginPage" });
      //   return;
      // }
      $f7router.navigate({ name: 'productViewPage', params: { code: ProductKey }, query: { bundleId: props.bundleId, sku: props.data.Sku } })
    }

    return { helpers, onViewProduct, rating, userToken, languageCode, store }
  }
})
</script>

<style scoped>
.product-grid .product-info {
  text-align: center;
  margin: 10px 15px 5px;
}
.sale-text p {
  margin: 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.sale-text {
  margin-top: 10px;
  padding: 15px;
  font-size: 12px;
  border-top: 1px solid #f1f1f1;
  font-weight: 700;
}
.sale-image {
  background: #fff;
  border-radius: 10px;
}
.sale-container {
  position: relative;
  background-image: linear-gradient(to right, #0c4d8f, #0080ff);
  padding: 8px;
}
.packing-qty-grid {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 5px;
  right: 10px;
  bottom: 10px;
  z-index: 9999;
  font-size: 12px;
}

.prod-price-b {
  color: #222;
  opacity: 1;
  font-weight: 300 !important;
}

.ribbon-new-grid {
  width: 80px;
  height: 80px;
  overflow: hidden;
  position: absolute;
}
.ribbon-best-grid,
.ribbon-hot-grid,
.ribbon-sal-grid {
  width: 50px !important;
  height: 50px;
  overflow: hidden;
  position: absolute;
}

.ribbon-new-grid::before,
.ribbon-new-grid::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  /* border: 5px solid #b72f2c; */
}

.ribbon-new-grid span {
  position: absolute;
  display: block;
  width: 70px;
  padding: 5px 0;
  background-color: #ff4d4d;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  text-transform: uppercase;
  text-align: center;
}
.ribbon-hot-grid span {
  position: absolute;
  display: flex;
  height: 50px;
  width: 50px;
  color: #fff;
  font-size: 8px;
  text-transform: uppercase;
  text-align: center;
  z-index: 5;
  justify-content: center;
  align-items: center;
}
.ribbon-sal-grid span {
  position: absolute;
  display: flex;
  height: 50px;
  width: 50px;
  color: #fff;
  font-size: 8px;
  text-transform: uppercase;
  text-align: center;
  z-index: 5;
  justify-content: center;
  align-items: center;
}
.ribbon-best-grid span {
  position: absolute;
  display: flex;
  height: 50px;
  width: 30px;
  color: #fff;
  font-size: 6px;
  text-transform: uppercase;
  text-align: center;
  z-index: 5;
  justify-content: center;
  align-items: center;
  left: 10px;
}

.ribbon-top-left-new-grid {
  top: -20px;
  left: 0px;
}
.ribbon-top-left-hot-grid {
  top: 60px;
  left: 5px;
}
.ribbon-top-left-sal-grid {
  top: 100px;
  left: 5px;
}
.ribbon-top-left-best-grid {
  top: 60px;
  left: 5px;
}
.ribbon-soon {
  /* top: -12px; */
  top: 0px;
  left: 0px;
}

.ribbon-top-left-new-grid span {
  /* right: 30px;
  top: 18px; */
  right: 28px;
  top: 18px;
  transform: rotate(-45deg);
  z-index: 5;
}
/* .ribbon-top-left-hot-grid span {
  right: 27px;
  top: 16px;
} */
/* .ribbon-top-left-sal-grid span {
  right: 25px;
  top: 16px;
} */

.ribbon-container-grid {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  left: 0px;
}
.ribbon-container-grid > div {
  flex-direction: column;
  justify-content: flex-start;
}

.bg-sticker-grid {
  top: 7px;
  right: 0px;
  position: relative;
}
.bg-sticker-grid img {
  width: 35px !important;
  position: relative !important;
  z-index: 3 !important;
}
</style>
